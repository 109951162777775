import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import React, { useEffect, useState } from 'react'
import ReactInputMask from 'react-input-mask'
import { Link, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { get, post } from '../../../../helpers/api_helper'
import { Policy } from './policy'
import CustomizedDialogs from './popup'
import SuccessPopup from './successPopup'
import { Card, CardText, Col } from 'reactstrap'

export const Registerform = () => {
  const history = useHistory()
  useEffect(() => {
    get(`/api/v1/address/country/all`)
      .then((data) => {
        setAllCountry(data.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])
  const [inputs, setInputs] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    password: '',
    address1: '',
    address2: '',
    country: '',
    state: '',
    city: '',
    zipcode: '',
    arbaNo: '',
  })
  const [allCountry, setAllCountry] = useState([])
  const [allState, setAllState] = useState([])
  const [showPassword, setShowPassword] = useState(false)
  const [showPassword1, setShowPassword1] = useState(false)
  const [clickLoading, setClickLoading] = useState(false)
  let showPwd1 = () => {
    setShowPassword1(true)
  }
  let hidePwd1 = () => {
    setShowPassword1(false)
  }
  let showPwd = () => {
    setShowPassword(true)
  }
  let hidePwd = () => {
    setShowPassword(false)
  }
  let [showBtn, setShowBtn] = useState(true)
  let [showSuccessPopup, setShowSuccessPopup] = useState(false)
  let [backendErr, setBackendErr] = useState({})
  const [showAlert, setShowAlert] = useState('')
  const [msg, setMsg] = useState('')
  const [errors, setErrors] = useState({
    email: null,
    vemail: null,
    firstName: null,
    lastName: null,
    password: null,
    confirmPassword: null,
    address1: null,
    country: null,
    state: null,
    city: null,
    zipcode: null,
    phone: null,
    arbaNo: null,
    check: null,
  })

  const [errorsMsg, setErrorsMsg] = useState({})

  const handleChange = (event) => {
    setBackendErr({})
    let name = event.target.name
    let value = event.target.value
    if (name === 'check') {
      value = event.target.checked
    }

    if (name === 'email') {
      value = value.replace(/\s/g, '').trim()
      if (value) {
        get(`/api/v1/register/is_email_exist?email=${value}&user_role=user`)
          .then((data) => {
            setBackendErr({})
            if (data.is_exist === true && data.account_status === 'VERIFIED') {
              setBackendErr((values) => ({
                ...values,
                email: data.account_status,
              }))
            } else if (
              data.is_exist === true &&
              data.account_status === 'UNVERIFIED'
            ) {
              setShowAlert(true)
              setShowBtn(true)
              setMsg(
                'Your account is not verified yet. Please click on resend verification link on website. Verify your account to login.'
              )
            } else {
              setErrors((values) => ({
                ...values,
                email: false,
              }))
              setShowAlert(false)
              setShowBtn(false)
              setMsg('')
            }
          })
          .catch((error) => {})
      }
      setInputs((values) => ({ ...values, [name]: value.trim() }))
      return
    } else if (name == 'phone') {
      // let newvalue = value.trim().replace(/-/gm, '');
      // if (newvalue.length > 3) {
      //   value = `${newvalue.substring(0, 3)}-${newvalue.substring(
      //     3,
      //     6
      //   )}-${newvalue.substring(6, 10)}`;
      //   value = value.trim().replace(/^-|-$/g, '');
      // }
      // value.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
    }
    setInputs((values) => ({ ...values, [name]: value }))
    if (name == 'password') {
      let newvalue = value.replaceAll(' ', '')
      setInputs((values) => ({ ...values, [name]: newvalue }))
    } else if (name == 'confirmPassword') {
      let newvalue = value.replaceAll(' ', '')
      setInputs((values) => ({ ...values, [name]: newvalue }))
    }
  }

  let handleClicked = () => {
    setClickLoading(true)
    post(`/api/v1/user_activation/resend_verify_link`, {
      email: inputs.email,
    })
      .then((response) => {
        setShowBtn(false)
        setMsg(
          'Email verify link has been successfully sent to your registered Email'
        )
        setTimeout(() => {
          setClickLoading(false)
          setShowAlert(false)
          setMsg('')
          history.push({ pathname: '/events' })
        }, 3000)
      })
      .catch((error) => {
        setClickLoading(false)
        console.log(error)
      })
  }
  let handleCountryChange = () => {
    setAllState([])
    let d = document.getElementById('country').value
    setInputs((values) => ({
      ...values,
      country: d == 'Select Country' ? null : d,
    }))
    setInputs((values) => ({ ...values, state: '' }))
    setInputs((values) => ({ ...values, city: '' }))
    setInputs((values) => ({ ...values, zipcode: '' }))

    get(`/api/v1/address/state/all?country_id=${d}&_order=asc&_sort=state_code`)
      .then((data) => {
        setAllState(data.data)
      })
      .catch((error) => {
        console.log(error)
      })

    //alert(d);
  }

  let handleStateChange = () => {
    let d = document.getElementById('state').value
    setInputs((values) => ({ ...values, state: d }))
    setInputs((values) => ({ ...values, city: '' }))
    setInputs((values) => ({ ...values, zipcode: '' }))
  }
  let experss =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/g
  const emailRegex = new RegExp(experss)
  let spaceRegex = /^\S.+\S$/g
  const spaceExp = new RegExp(spaceRegex)

  const handleReg = (e) => {
    e.preventDefault()
    let err = {}
    err.email = !inputs.email.toString().trim() ? true : null
    err.vemail = inputs.email && !emailRegex.test(inputs.email) ? true : null
    err.confirmPassword = !inputs.confirmPassword ? true : null
    err.pwdandconpwd = inputs.confirmPassword != inputs.password ? true : null
    // err.firstName = !inputs.firstName.toString().trim() ? true : null;
    // err.lastName = !inputs.lastName.toString().trim() ? true : null;
    let onlyCharExpr = /^[a-zA-Z \-]+$/g
    let charRegex = new RegExp(onlyCharExpr)

    if (inputs.firstName.trim().length === 0) {
      err.firstName = 'First name is required'
    } else if (inputs.firstName.trim().length > 50) {
      err.firstName =
        'First name must be of minimum 1 and maximum 50 character length'
    } else {
      if (!inputs.firstName.trim().match(charRegex)) {
        err.firstName = 'First name field must be alphabetic'
      }
    }

    if (inputs.lastName.trim().length === 0) {
      err.lastName = 'Last name is required'
    } else if (inputs.lastName.trim().length > 50) {
      err.lastName =
        'Last name must be of minimum 1 and maximum 50 character length'
    } else {
      if (!inputs.lastName.trim().match(charRegex)) {
        err.lastName = 'Last name field must be alphabetic'
      }
    }

    let textRegex =
      /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).{8,15}$/
    // /^(?=.*([A-Z]){1,})(?=.*[!@#$:=%^*-_+?;<>,.&*]{1,})(?=.*[0-9]{1,})(?=.*[a-z]{1,}).{8,15}$/;

    if (inputs.password.trim().length === 0) {
      err.password = 'Password is required'
    } else if (inputs.password && !textRegex.test(inputs.password)) {
      err.password =
        'Password must have: Minimum 8 and maximum 15 characters, at least one uppercase letter, one lowercase letter, one number and one special character'
    } else {
      err.password = null
    }

    if (inputs.city.trim().length === 0) {
      err.city = 'City field is required'
    } else if (inputs.city.trim().length > 50) {
      err.city =
        'City field must be of minimum 1 and maximum 50 character length'
    } else {
      if (!inputs.city.trim().match(charRegex)) {
        err.city = 'City field must be alphabetic'
      }
    }
    // err.password = !inputs.password ? true : null;
    err.address1 = !inputs.address1.trim() ? true : null
    //alert(inputs.country)
    err.country = !inputs.country ? true : null
    err.state = !inputs.state ? true : null
    // err.city = !inputs.city.trim() ? true : null;
    err.zipcode = !inputs.zipcode.toString().trim() ? true : null
    err.check = inputs.check != true ? true : null
    // err.phone = !inputs.phone.toString().trim() ? true : null;

    const finalVal = inputs.phone.replace(/-/gm, '')
    console.log(finalVal.length)
    if (!inputs.phone.toString().trim()) {
      err.phone = 'Phone number is Required'
    } else if (inputs.phone.replace(/[^0-9]/g, '').length !== 10) {
      err.phone = 'Phone Number must be 10 digits'
    } else {
      err.phone = ''
    }
    setErrors(err)
    if (!Object.values(err).includes(true)) {
      document.getElementById('submit').setAttribute('disabled', true)
      post(`/api/v1/register`, {
        user_role: 'user',
        first_name: inputs.firstName.toString().trim(),
        last_name: inputs.lastName.toString().trim(),
        email: inputs.email.toString().trim(),
        phone: inputs.phone?.replace(/-/gm, ''),
        password: inputs.password,
        address_1: inputs.address1.toString().trim(),
        address_2: inputs.address2.toString().trim(),
        country_id: inputs.country.toString().trim(),
        state_id: inputs.state,
        city: inputs.city,
        zipcode: inputs.zipcode.toString().trim(),
        arba_number: inputs.arbaNo.toString().trim(),
      })
        .then((data) => {
          setInputs({})
          setShowSuccessPopup(true)
          setShowAlert(true)
          setMsg(`${data.title} ${data.message}`)
          setShowBtn(false)
          setTimeout(() => {
            setShowSuccessPopup(false)
            setShowAlert(false)
            setMsg('')
            history.push({ pathname: '/events' })
          }, 3600)
        })
        .catch((error) => {
          document.getElementById('submit').removeAttribute('disabled')
          if (error.response.status === 422) {
            var obj = {}
            console.log(error.response.data[0])
            obj = error.response.data.find((o) => {
              return (
                o.msg ===
                'Email already in use. Please try with different email address.'
              )
            })

            error.response.data.map((item, index) => {
              toast.error(item.msg, {
                autoClose: 10000,
                toastId: index,
              })
            })
          }
        })
    } else {
      console.log('errors')
    }
  }
  return (
    <React.Fragment>
      <div className='page-content-auth mt'>
        <div className='container-fuild'>
          <div className='row'>
            <Col md={12} sm={12} lg={12}>
              <Card body color='primary' inverse className='p-3'>
                <CardText>
                  Ready to set up your <b>Free Easy2Show</b> Account? By
                  creating your new account, you will be able to enter all
                  events listed on our <b>Events tab</b>. After your basic
                  account is active, you can choose to upgrade to our{' '}
                  <b>Premier Member</b> account which gives you access to more
                  information, features and offers.
                </CardText>
              </Card>
            </Col>
            <div className='text-left col-lg-12'>
              <div className='page-title-box justify-content-between'>
                <h2 className='mb-4'> Create Your Account</h2>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-12'>
              <form onSubmit={handleReg} autocomplete='off'>
                <div className='row'>
                  <div className='col-xl-6'>
                    <div className='mb-3'>
                      <label for='' className='form-label'>
                        First Name *
                      </label>
                      <input
                        type='text'
                        name='firstName'
                        value={inputs.firstName || ''}
                        onChange={handleChange}
                        placeholder='Enter First Name'
                        className='form-control'
                        style={{ borderColor: 'black' }}
                        autoFocus
                      />
                      {errors.firstName && (
                        <p style={{ color: 'red' }}>{errors.firstName}</p>
                      )}
                    </div>
                  </div>
                  <div className='col-xl-6'>
                    <div className='mb-3'>
                      <label for='' className='form-label'>
                        Last Name *
                      </label>
                      <input
                        type='text'
                        name='lastName'
                        value={inputs.lastName || ''}
                        onChange={handleChange}
                        placeholder='Enter Last Name'
                        className='form-control'
                        style={{ borderColor: 'black' }}
                      />
                      {errors.lastName && (
                        <p style={{ color: 'red' }}>{errors.lastName}</p>
                      )}
                    </div>
                  </div>
                  <div className='col-xl-12'>
                    <div className='mb-3'>
                      <label for='' className='form-label'>
                        Email * ( Note - Email will be used as Username. )
                      </label>
                      <input
                        type='email'
                        name='email'
                        autocomplete='off'
                        value={
                          inputs?.email
                            ? inputs?.email.replace(/\s/g, '').trim()
                            : ''
                        }
                        onChange={handleChange}
                        placeholder='Email Id'
                        className='form-control'
                        style={{ borderColor: 'black' }}
                      />
                      {!backendErr.email && errors.email && (
                        <p style={{ color: 'red' }}>
                          Email address is required
                        </p>
                      )}
                      {!backendErr.email && !errors.email && errors.vemail && (
                        <p style={{ color: 'red' }}>Invalid email address.</p>
                      )}
                      {backendErr.email && backendErr.email === 'VERIFIED' && (
                        <p style={{ color: 'red' }}>
                          Email already exist{' '}
                          <span
                            style={{
                              color: '#6a93ff',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              history.push({
                                pathname: '/login',
                              })
                            }}
                          >
                            Click Here
                          </span>{' '}
                          to login
                        </p>
                      )}
                    </div>
                  </div>
                  <div className='col-xl-6'>
                    <div className='mb-3'>
                      <label for='' className='form-label'>
                        Password *
                      </label>
                      <input
                        name='password'
                        value={inputs.password || ''}
                        onChange={handleChange}
                        placeholder='Password'
                        className='form-control'
                        style={{ borderColor: 'black' }}
                        type={showPassword ? 'text' : 'password'}
                      />
                      {showPassword && (
                        <RemoveRedEyeIcon
                          className='pwd-icon'
                          onClick={hidePwd}
                        />
                      )}
                      {!showPassword && (
                        <VisibilityOffIcon
                          className='pwd-icon'
                          onClick={showPwd}
                        />
                      )}
                      {errors.password && (
                        <p style={{ color: 'red' }}>{errors.password}</p>
                      )}
                      <span className='text-muted'>
                        (Note: Minimum 8 and maximum 15 characters, at least one
                        uppercase letter, one lowercase letter, one number and
                        one special character)
                      </span>
                    </div>
                  </div>
                  <div className='col-xl-6'>
                    <div className='mb-3'>
                      <label for='' className='form-label'>
                        Confirm Password *
                      </label>
                      <input
                        name='confirmPassword'
                        value={inputs.confirmPassword || ''}
                        onChange={handleChange}
                        placeholder='Confirm Password'
                        className='form-control'
                        style={{ borderColor: 'black' }}
                        type={showPassword1 ? 'text' : 'password'}
                      />
                      {showPassword1 && (
                        <RemoveRedEyeIcon
                          className='pwd-icon'
                          onClick={hidePwd1}
                        />
                      )}
                      {!showPassword1 && (
                        <VisibilityOffIcon
                          className='pwd-icon'
                          onClick={showPwd1}
                        />
                      )}
                      {errors.confirmPassword && (
                        <p style={{ color: 'red' }}>
                          Confirm Password is Required
                        </p>
                      )}
                      {!errors.confirmPassword && errors.pwdandconpwd && (
                        <p style={{ color: 'red' }}>
                          Password and Confirm password should be same
                        </p>
                      )}
                    </div>
                  </div>
                  <div className='col-xl-12'>
                    <div className='mb-3'>
                      <label for='' className='form-label'>
                        Address 1 *
                      </label>
                      <input
                        type='text'
                        name='address1'
                        placeholder='Enter Address 1'
                        className='form-control'
                        value={inputs.address1 || ''}
                        onChange={handleChange}
                        style={{ borderColor: 'black' }}
                      />
                      {errors.address1 && (
                        <p style={{ color: 'red' }}>Address 1 is Required</p>
                      )}
                    </div>
                  </div>
                  <div className='col-xl-12'>
                    <div className='mb-3'>
                      <label for='' className='form-label'>
                        Address 2{' '}
                      </label>
                      <input
                        type='text'
                        name='address2'
                        placeholder='Enter Address 2'
                        className='form-control'
                        value={inputs.address2 || ''}
                        onChange={handleChange}
                        style={{ borderColor: 'black' }}
                      />
                    </div>
                  </div>
                  <div className='col-xl-4'>
                    <div className='mb-3'>
                      <label
                        for='formrow-firstname-input'
                        className='form-label'
                      >
                        Country *
                      </label>
                      <select
                        type='text'
                        name='country'
                        id='country'
                        className='form-control form-select'
                        value={inputs.country || ''}
                        onChange={(e) => {
                          //handleChange();
                          handleCountryChange()
                        }}
                        style={{
                          borderColor: 'black',
                        }}
                      >
                        <option>Select Country</option>
                        {allCountry.map((country) => {
                          return (
                            <option
                              value={country.country_id}
                              //selected={country.country_id == inputs.country_id}
                            >
                              {country.country_name}
                            </option>
                          )
                        })}
                      </select>
                      {errors.country && (
                        <p style={{ color: 'red' }}>Please Select Country</p>
                      )}
                    </div>
                  </div>
                  <div className='col-xl-4'>
                    <div className='mb-3'>
                      <label
                        for='formrow-firstname-input'
                        className='form-label'
                      >
                        State *
                      </label>

                      <select
                        type='text'
                        name='state'
                        id='state'
                        className='form-control form-select'
                        value={inputs.state || ''}
                        onChange={(e) => {
                          //handleChange();
                          handleStateChange()
                        }}
                        style={{
                          borderColor: 'black',
                        }}
                      >
                        <option value=''>Select State</option>
                        {allState.map((state) => {
                          return (
                            <option value={state.state_id}>
                              {state.state_code}
                            </option>
                          )
                        })}
                      </select>
                      {errors.state && (
                        <p style={{ color: 'red' }}>Please Select State</p>
                      )}
                    </div>
                  </div>
                  <div className='col-xl-4'>
                    <div className='mb-3'>
                      <label
                        for='formrow-firstname-input'
                        className='form-label'
                      >
                        City *
                      </label>
                      <input
                        type='text'
                        name='city'
                        value={inputs.city || ''}
                        onChange={handleChange}
                        placeholder='Enter City'
                        className='form-control'
                        style={{ borderColor: 'black' }}
                      />
                      {errors.city && (
                        <p style={{ color: 'red' }}>{errors.city}</p>
                      )}
                    </div>
                  </div>
                  <div className='col-xl-4'>
                    <div className='mb-3'>
                      <label for='' className='form-label'>
                        Zipcode *
                      </label>
                      <input
                        type='text'
                        name='zipcode'
                        placeholder='Enter Zipcode'
                        className='form-control'
                        value={inputs.zipcode || ''}
                        onChange={handleChange}
                        style={{ borderColor: 'black' }}
                      />
                      {errors.zipcode && (
                        <p style={{ color: 'red' }}>Zipcode is Required</p>
                      )}
                    </div>
                  </div>
                  <div className='col-xl-4'>
                    <div className='mb-3'>
                      <label for='' className='form-label'>
                        Phone *
                      </label>
                      <ReactInputMask
                        mask='999-999-9999'
                        name='phone'
                        value={inputs.phone || ''}
                        className='form-control'
                        placeholder='Enter Phone'
                        onChange={handleChange}
                        style={{ borderColor: 'black' }}
                      />
                      {/* <input
                        type="text"
                        name="phone"
                        value={inputs.phone || ''}
                        onChange={handleChange}
                        placeholder="Enter Phone"
                        className="form-control"
                        style={{ borderColor: 'black' }}
                      /> */}
                      {errors.phone && (
                        <p style={{ color: 'red' }}>{errors.phone}</p>
                      )}
                    </div>
                  </div>
                  <div className='col-xl-4'>
                    <div className='mb-3'>
                      <label for='' className='form-label'>
                        ARBA No.
                      </label>
                      <input
                        type='text'
                        name='arbaNo'
                        value={inputs.arbaNo || ''}
                        onChange={handleChange}
                        placeholder='Enter ARBA No.'
                        className='form-control'
                        style={{ borderColor: 'black' }}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className='row'>
            <Policy />
            <div className='col-xl-12'>
              <div className='mb-3 mt-3 agree-check res-agree-check'>
                <input
                  className='form-check-input mr-2'
                  type='checkbox'
                  name='check'
                  id='check'
                  value={1}
                  onClick={handleChange}
                  checked={inputs.check == true}
                />
                <label className='form-check-label p-r-3' for='check'>
                  I have read and agree to the Website Terms Conditions &
                  Privacy Policy *
                </label>
              </div>
              {errors.check && (
                <p style={{ color: 'red' }}>
                  Please Accept Terms and Conditions
                </p>
              )}
            </div>
            <div className='col-lg-12'>
              <div className='mt-4 text-center'>
                <button
                  type='submit'
                  className='w-lg waves-effect waves-light btn btn-success'
                  onClick={handleReg}
                  id='submit'
                >
                  SUBMIT
                </button>
                <div className='mt-4 text-center fs-20'>
                  Already have an account?{' '}
                  <Link to='/login' className='font-weight-bold link'>
                    Login
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        {!showSuccessPopup && (
          <CustomizedDialogs
            setShowAlert={setShowAlert}
            msg={msg}
            showAlert={showAlert}
            setMsg={setMsg}
            setShowBtn={setShowBtn}
            showBtn={showBtn}
            loading={clickLoading}
            handleClicked={handleClicked}
          />
        )}
        {showSuccessPopup && (
          <SuccessPopup
            setShowAlert={setShowAlert}
            msg={msg}
            showAlert={showAlert}
            setMsg={setMsg}
            setShowBtn={setShowBtn}
            showBtn={showBtn}
            handleClicked={handleClicked}
            loading={clickLoading}
          />
        )}
      </div>
    </React.Fragment>
  )
}
