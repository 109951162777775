import SearchIcon from '@mui/icons-material/Search'
import React, { useEffect, useState } from 'react'
// import 'react-datepicker/dist/react-datepicker.css'
import Flatpickr from 'react-flatpickr'
import 'flatpickr/dist/themes/material_blue.css'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { Col, Input, InputGroup, Modal, Row } from 'reactstrap'
import { get } from '../../../helpers/api_helper'
import { getDbDate } from '../../../helpers/date_helper'
import { getAllEvent } from '../../../store/event/actions'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import DateFilterByMonth from './DateFilterByMonth/DateFilterByMonth'
import moment from 'moment'
import _ from 'lodash'
import { allStateList, resetState } from '../../../store/actions'

function removeBodyCss() {
  document.body.classList.add('no_padding')
}
export const EventSearch = ({ inputs, setInputs }) => {
  let selectStart
  if (inputs.startDate) {
    selectStart = new Date(inputs.startDate)
    selectStart.setDate(selectStart.getDate() + 1)
  }

  const [modal_center, setmodal_center] = useState(false)
  const [allCat, setAllCat] = useState([])
  const dispatch = useDispatch()
  const { currentPage, perPage } = useSelector(({ pagination }) => pagination)
  const { CountryList } = useSelector((state) => state?.Country)
  const { StateList } = useSelector((state) => state?.State)

  useEffect(() => {
    get(`/api/v1/breed/category/all`)
      .then((responce) => {
        setAllCat(responce.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  useEffect(() => {
    if (inputs?.country_id) {
      let params = {
        country_id: inputs?.country_id,
      }
      dispatch(allStateList(params))
    } else {
      dispatch(resetState())
    }
  }, [inputs?.country_id])

  //
  const handleChange = (event) => {
    let name = event.target.name
    let value = event.target.value
    //alert(value);
    if (name === 'search_text') {
      setInputs((values) => ({ ...values, [name]: value?.trim() }))
    } else {
      if (name === 'active5') {
        setInputs((values) => ({
          ...values,
          [name]: value,
          startDate: '',
          endDate: '',
        }))
      } else if (name === 'country_id') {
        setInputs((values) => ({
          ...values,
          [name]: value,
          state_id: '',
        }))
      } else {
        setInputs((values) => ({ ...values, [name]: value }))
      }
    }
  }
  const handleChangeSD = ([val]) => {
    setInputs((values) => ({ ...values, startDate: val }))
  }
  const handleChangeED = ([val]) => {
    setInputs((values) => ({ ...values, endDate: val }))
  }

  const handleChangeByMonth = ([val]) => {
    if (val) {
      const firstMonth = moment(val).startOf('month').toDate()
      const lastMonth = moment(val).endOf('month').toDate()
      setInputs((values) => ({
        ...values,
        startDate: firstMonth,
        endDate: lastMonth,
      }))
    } else {
      setInputs((values) => ({
        ...values,
        startDate: '',
        endDate: '',
      }))
    }
  }
  let handleSearch = (value = '', isSearch = false) => {
    //alert(1);
    let data = {}
    if (inputs.search !== '') {
      data.search_text = inputs?.search?.trim()
    }
    if (isSearch) {
      data.search_text = value?.trim()
    }
    if (inputs.startDate) {
      data.start_date = getDbDate(inputs.startDate)
    }
    if (inputs.endDate) {
      data.end_date = getDbDate(inputs.endDate)
    }
    if (inputs.active1 !== 'both') {
      data.event_type = inputs.active1
    }
    if (inputs.active2 !== 'both') {
      data.show_type = inputs.active2
    }
    if (inputs.active3 !== 'both') {
      data.show_category = inputs.active3
    }
    if (inputs.active4 !== 'all') {
      data.breed_category_id = inputs.active4
    }

    /////////////////////////////////////////////
    if (inputs.country_id) {
      data.country_id = Number(inputs.country_id)
    }

    if (inputs.state_id) {
      data.state_id = Number(inputs.state_id)
    }
    dispatch(getAllEvent(1, perPage, data))
  }

  let handleFilter = () => {
    // let startDate = document.getElementById('StartFlatpickr').value
    // if (startDate) {
    //   setInputs((values) => ({ ...values, startDate: startDate }))
    // }
    // let endDate = document.getElementById('EndFlatpickr').value
    // if (endDate) {
    //   setInputs((values) => ({ ...values, endDate: endDate }))
    // }
    //alert(inputs.startDate);
    //alert(inputs.endDate);
    // console.log(inputs);
    let data = {}
    if (inputs.search) {
      data.search_text = inputs.search
    }
    if (inputs.startDate) {
      data.start_date = inputs?.startDate ? getDbDate(inputs.startDate) : ''
    }
    if (inputs.endDate) {
      data.end_date = inputs?.endDate ? getDbDate(inputs.endDate) : ''
    }
    if (inputs.active1 !== 'both') {
      data.event_type = inputs.active1
    }
    if (inputs.active2 !== 'both') {
      data.show_type = inputs.active2
    }
    if (inputs.active3 !== 'both') {
      data.show_category = inputs.active3
    }
    if (inputs.active4 !== 'all') {
      data.breed_category_id = inputs.active4
    }
    if (inputs.country_id) {
      data.country_id = Number(inputs.country_id)
    }

    if (inputs.state_id) {
      data.state_id = Number(inputs.state_id)
    }

    let validationFlag = false
    if (inputs?.startDate && !inputs?.endDate) {
      validationFlag = true
      toast.error('Please select end date', { toastId: 1 })
    }
    if (!inputs?.startDate && inputs?.endDate) {
      validationFlag = true
      toast.error('Please select start date', { toastId: 1 })
    }
    if (!validationFlag) {
      dispatch(getAllEvent(1, perPage, data))
      setmodal_center(false)
    }
    // else {

    // }
  }

  function tog_center() {
    setmodal_center(!modal_center)
    removeBodyCss()
  }
  return (
    <React.Fragment>
      <div className='col-md-12 mb-7'>
        <div className='card shadow-only-hover1'>
          <div className='card-body event-card-body'>
            <div className='col-lg-12 col-xl-12 event-box'>
              <div className='row'>
                <div className='col-lg-10 col-xl-10 box-head mb-4 res-p-r'>
                  Search{' '}
                  <input
                    type='text'
                    name='search'
                    value={inputs.search}
                    onChange={(e) => {
                      handleChange(e)
                      handleSearch(e.target.value, true)
                    }}
                    placeholder='Search by typing Event Name or City or State.'
                    className='search-input'
                  />
                  <SearchIcon
                    className='search-btn'
                    onClick={() => {
                      handleSearch()
                    }}
                  />
                </div>
                <div className='col-lg-2 col-xl-2 box-head mb-4'>
                  <button
                    type='button'
                    className='btn btn-primary text-left w-100 flt-btn'
                    onClick={() => {
                      tog_center()
                    }}
                    data-toggle='modal'
                    data-target='.bs-example-modal-center'
                  >
                    Filter{' '}
                    <i
                      className='fa fa-filter filter-icon'
                      style={{ marginLeft: '50px' }}
                    ></i>
                  </button>
                  <Modal
                    className='filter-modal'
                    isOpen={modal_center}
                    toggle={() => {
                      tog_center()
                    }}
                    centered={true}
                  >
                    <div className='modal-header'>
                      <h5 className='modal-title mt-0'>Filter</h5>
                      <button
                        type='button'
                        onClick={() => {
                          setmodal_center(false)
                        }}
                        className='close-btn'
                        data-dismiss='modal'
                        aria-label='Close'
                      >
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <div className='modal-body'>
                      <Row className='mb-4'>
                        <Col lg={12} className='mb-4'>
                          <Row>
                            <Col lg={2} className='res-mb-2'>
                              {' '}
                              <label
                                className='form-check-label mt-1 '
                                htmlFor='check1'
                              >
                                Date Type:{' '}
                              </label>
                            </Col>
                            <Col lg={3} className='res-mb-2'>
                              <input
                                className='form-check-input'
                                type='radio'
                                name='active5'
                                id='active5-filter_by_date_range'
                                onChange={handleChange}
                                value='filter_by_date_range'
                                checked={`${
                                  inputs.active5 === 'filter_by_date_range'
                                    ? 'checked'
                                    : ''
                                }`}
                              />
                              <label
                                className='form-check-label pr-10 mr-5'
                                htmlFor='active5-filter_by_date_range'
                              >
                                Filter By Date Range
                              </label>
                            </Col>
                            <Col lg={3} className='res-mb-2'>
                              <input
                                className='form-check-input'
                                type='radio'
                                name='active5'
                                id='active5-filter_by_month'
                                value='filter_by_month'
                                onChange={handleChange}
                                checked={`${
                                  inputs.active5 === 'filter_by_month'
                                    ? 'checked'
                                    : ''
                                }`}
                              />
                              <label
                                className='form-check-label pr-10 mr-5'
                                htmlFor='active5-filter_by_month'
                              >
                                Filter By Month
                              </label>
                            </Col>
                          </Row>
                        </Col>
                        {inputs.active5 === 'filter_by_month' ? (
                          <DateFilterByMonth
                            onChange={handleChangeByMonth}
                            inputs={inputs}
                          />
                        ) : (
                          <React.Fragment>
                            <Col lg={2}>
                              <label
                                className='form-check-label mt-3 '
                                htmlFor='check1'
                              >
                                Start Date:
                              </label>
                            </Col>

                            <Col lg={4}>
                              <div className=''>
                                <InputGroup>
                                  <Flatpickr
                                    id='StartFlatpickr'
                                    className='form-control d-block glyphicon glyphicon-calendar'
                                    placeholder={`Start Date`}
                                    name='startDate'
                                    options={{
                                      minDate: '2018-01-01',
                                      dateFormat: 'm-d-Y',
                                      maxDate: new Date(inputs.endDate) - 1,
                                    }}
                                    value={
                                      inputs.startDate ? inputs.startDate : ''
                                    }
                                    style={{
                                      width: '100%',
                                    }}
                                    onChange={handleChangeSD}
                                  />
                                  <i className='fa fa-calendar cal-icon'></i>
                                </InputGroup>
                              </div>
                            </Col>
                            <Col lg={2}>
                              <label
                                className='form-check-label mt-3 '
                                htmlFor='check1'
                              >
                                End Date :
                              </label>
                            </Col>
                            <Col lg={4}>
                              <div className=''>
                                <InputGroup>
                                  <Flatpickr
                                    id='EndFlatpickr'
                                    className='form-control d-block'
                                    placeholder={`End Date`}
                                    options={{
                                      dateFormat: 'm-d-Y',
                                      minDate: selectStart,
                                    }}
                                    value={inputs.endDate ? inputs.endDate : ''}
                                    style={{
                                      width: '100%',
                                    }}
                                    onChange={handleChangeED}
                                  />
                                  <i className='fa fa-calendar cal-icon'></i>
                                </InputGroup>
                              </div>
                            </Col>
                          </React.Fragment>
                        )}
                      </Row>

                      <Row className='mb-4'>
                        <Col lg={2} className='res-mb-2'>
                          {' '}
                          <label
                            className='form-check-label mt-1 '
                            htmlFor='check1'
                          >
                            Event Type :{' '}
                          </label>
                        </Col>
                        <Col lg={3} className='res-mb-2'>
                          <input
                            className='form-check-input'
                            type='radio'
                            name='active1'
                            id='active1'
                            onChange={handleChange}
                            value='national'
                            checked={`${
                              inputs.active1 === 'national' ? 'checked' : ''
                            }`}
                          />
                          <label
                            className='form-check-label pr-10 mr-5'
                            htmlFor='active1'
                          >
                            National
                          </label>
                        </Col>
                        <Col lg={3} className='res-mb-2'>
                          <input
                            className='form-check-input'
                            type='radio'
                            name='active1'
                            id='active2'
                            value='non-national'
                            onChange={handleChange}
                            checked={`${
                              inputs.active1 === 'non-national' ? 'checked' : ''
                            }`}
                          />
                          <label
                            className='form-check-label pr-10 mr-5'
                            htmlFor='active2'
                          >
                            Non-National
                          </label>
                        </Col>
                        <Col lg={3} className='res-mb-2'>
                          <input
                            className='form-check-input'
                            type='radio'
                            name='active1'
                            id='active3'
                            onChange={handleChange}
                            value='both'
                            checked={`${
                              inputs.active1 === 'both' ? 'checked' : ''
                            }`}
                          />
                          <label
                            className='form-check-label pr-10 mr-5'
                            htmlFor='active3'
                          >
                            Both
                          </label>
                        </Col>
                      </Row>

                      <Row className='mb-4'>
                        <Col lg={2} className='res-mb-2'>
                          {' '}
                          <label
                            className='form-check-label mt-1 '
                            htmlFor='check4'
                          >
                            Show Type :{' '}
                          </label>
                        </Col>
                        <Col lg={3} className='res-mb-2'>
                          <input
                            className='form-check-input'
                            type='radio'
                            name='active2'
                            id='active4'
                            value='open'
                            onChange={handleChange}
                            checked={`${
                              inputs.active2 === 'open' ? 'checked' : ''
                            }`}
                          />
                          <label
                            className='form-check-label pr-10 mr-5'
                            htmlFor='active4'
                          >
                            Open
                          </label>
                        </Col>
                        <Col lg={3} className='res-mb-2'>
                          <input
                            className='form-check-input'
                            type='radio'
                            name='active2'
                            id='active22'
                            value='youth'
                            onChange={handleChange}
                            checked={`${
                              inputs.active2 === 'youth' ? 'checked' : ''
                            }`}
                          />
                          <label
                            className='form-check-label pr-10 mr-5'
                            htmlFor='active22'
                          >
                            Youth
                          </label>
                        </Col>
                        <Col lg={3} className='res-mb-2'>
                          <input
                            className='form-check-input'
                            type='radio'
                            name='active2'
                            id='active5'
                            value='both'
                            onChange={handleChange}
                            checked={`${
                              inputs.active2 === 'both' ? 'checked' : ''
                            }`}
                          />
                          <label
                            className='form-check-label pr-10 mr-5'
                            htmlFor='active5'
                          >
                            Both
                          </label>
                        </Col>
                      </Row>

                      <Row className='mb-4'>
                        <Col lg={2} className='res-mb-2'>
                          {' '}
                          <label
                            className='form-check-label mt-1 '
                            htmlFor='check1'
                          >
                            Specialty :{' '}
                          </label>
                        </Col>
                        <Col lg={3} className='res-mb-2'>
                          <input
                            className='form-check-input'
                            type='radio'
                            name='active3'
                            id='active6'
                            value='non-speciality'
                            onChange={handleChange}
                            checked={`${
                              inputs.active3 === 'non-speciality'
                                ? 'checked'
                                : ''
                            }`}
                          />
                          <label
                            className='form-check-label pr-10 mr-5'
                            htmlFor='active6'
                          >
                            Non-specialty
                          </label>
                        </Col>
                        <Col lg={3} className='res-mb-2'>
                          <input
                            className='form-check-input'
                            type='radio'
                            name='active3'
                            id='active7'
                            value='speciality'
                            onChange={handleChange}
                            checked={`${
                              inputs.active3 === 'speciality' ? 'checked' : ''
                            }`}
                          />
                          <label
                            className='form-check-label pr-10 mr-5'
                            htmlFor='active7'
                          >
                            Specialty
                          </label>
                        </Col>
                        <Col lg={3} className='res-mb-2'>
                          <input
                            className='form-check-input'
                            type='radio'
                            name='active3'
                            id='active9'
                            value='both'
                            onChange={handleChange}
                            checked={`${
                              inputs.active3 === 'both' ? 'checked' : ''
                            }`}
                          />
                          <label
                            className='form-check-label pr-10 mr-5'
                            htmlFor='active9'
                          >
                            Both
                          </label>
                        </Col>
                      </Row>

                      <Row className='mb-4'>
                        <Col lg={2} className='res-mb-2'>
                          {' '}
                          <label
                            className='form-check-label mt-1 '
                            htmlFor='check1'
                          >
                            Category :{' '}
                          </label>
                        </Col>
                        <Col lg={3} className='mb-4'>
                          <input
                            className='form-check-input'
                            type='radio'
                            name='active4'
                            id='active8'
                            value='all'
                            onChange={handleChange}
                            checked={`${
                              inputs.active4 === 'all' ? 'checked' : ''
                            }`}
                          />
                          <label
                            className='form-check-label pr-10 mr-5'
                            htmlFor='active8'
                          >
                            All Categories
                          </label>
                        </Col>

                        {allCat?.map((item, index) => {
                          let margin = 0
                          if ((index + 1) % 3 === 0) {
                            margin = 150
                          }
                          return (
                            <Col
                              lg={3}
                              style={{
                                marginLeft: `${margin}px`,
                              }}
                              className='res-mb-2 res-ml-0'
                            >
                              <input
                                className='form-check-input'
                                type='radio'
                                name='active4'
                                id={`active${index + 10}`}
                                value={item.breed_category_id}
                                onChange={handleChange}
                                checked={`${
                                  inputs.active4 === item.breed_category_id
                                    ? 'checked'
                                    : ''
                                }`}
                              />

                              <label
                                className='form-check-label pr-10 mr-5'
                                htmlFor={`active-112xx${index + 10}`}
                              >
                                {item.category_name}
                              </label>
                            </Col>
                          )
                        })}
                      </Row>
                      <Row>
                        <Col lg={2} className='res-mb-2'>
                          {' '}
                          <label
                            className='form-check-label mt-1 '
                            htmlFor='check1'
                          >
                            Country :{' '}
                          </label>
                        </Col>
                        <Col lg={3} className='res-mb-2'>
                          <select
                            className='form-control'
                            name='country_id'
                            onChange={handleChange}
                            value={inputs.country_id}
                          >
                            <option value=''>Select Country</option>
                            {Array.isArray(CountryList) && _.size(CountryList)
                              ? CountryList.map((country) => {
                                  return (
                                    <option
                                      value={country.country_id}
                                      key={country.country_id}
                                    >
                                      {country.country_name}
                                    </option>
                                  )
                                })
                              : null}
                          </select>
                        </Col>
                        <Col lg={2} className='res-mb-2'>
                          {' '}
                          <label
                            className='form-check-label mt-1 '
                            htmlFor='check1'
                          >
                            State :{' '}
                          </label>
                        </Col>
                        <Col lg={3} className='res-mb-2'>
                          <Input
                            type='select'
                            className='form-control'
                            name='state_id'
                            onChange={handleChange}
                            value={inputs.state_id}
                          >
                            <option value=''>Select State</option>
                            {Array.isArray(StateList) && _.size(StateList)
                              ? StateList.map((state) => {
                                  return (
                                    <option
                                      value={state.state_id}
                                      key={state.state_id}
                                    >
                                      {state.state_code}
                                    </option>
                                  )
                                })
                              : null}
                          </Input>
                        </Col>
                      </Row>
                      <Col lg={12} className='text-center'>
                        <button
                          type='button'
                          className='btn btn-primary btn-easy'
                          onClick={handleFilter}
                        >
                          APPLY
                        </button>
                      </Col>
                    </div>
                  </Modal>
                </div>
                <div className='col-lg-6 col-xl-6 box-head text-left'>
                  {' '}
                  <i className='fa fa-circle icon-dot'></i> Only Events that
                  offer Online entry appear here.
                </div>
                <div className='col-lg-6 col-xl-6 box-head text-left'>
                  <i className='fa fa-circle icon-dot'></i> Some Events may
                  require you to pay your entries Online.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
