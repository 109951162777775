import $ from 'jquery'
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { get } from '../../../helpers/api_helper'
import { getDate, getTime } from '../../../helpers/date_helper'
import {
  setBtnTextp,
  setHandleClickp,
  setMsgp,
  setShowAlertp,
  setShowBtnp,
} from '../../../store/popup/actions'
import Popup from '../../CommonComponent/popup'
import SuccessPopup from '../../CommonComponent/successPopup'
import { AdditionalInformation } from './AdditionalInformation'
import { CostInformation } from './CostInformation'
import { DiscountInformation } from './DiscountInformation'
import { EventMultipleDeadline } from './EventMultipleDeadline'
import { GetInTouchModal } from './GetInTouchModal'
import DownloadCatalog from './DownloadCatalog'
import RunningTotal from './RunningTotal'
import { UrlEncodeHook } from '../../../hooks'
import _ from 'lodash'
import { Col, Modal, Row } from 'reactstrap'

function ordinal_suffix_of(i) {
  var j = i % 10,
    k = i % 100
  if (j === 1 && k !== 11) {
    return i + 'st'
  }
  if (j === 2 && k !== 12) {
    return i + 'nd'
  }
  if (j === 3 && k !== 13) {
    return i + 'rd'
  }
  return i + 'th'
}
function handleClick(id) {
  $(`#toggle-example-${id}`).toggle('collapse') // toggle collapse
}
function handlRunningTotalColapse(id) {
  //alert(id);
  $(`#RunningTotal-${id}`).toggle('collapse') // toggle collapse
}
function circle(id) {
  $(`#down-icon-${id}`).toggleClass('fa-chevron-circle-up')
  $(`#down-icon-${id}`).toggleClass('fa-chevron-circle-down')
}

function showpopup() {
  $('#popup_box').fadeToggle()
  $('#popup_box').css({ visibility: 'visible', display: 'block' })
  $('.view-main').css({ opacity: '0.5' })
  $('.header-main').css({ opacity: '1' })
}

function hidepopup() {
  $('#popup_box').fadeToggle()
  $('#popup_box').css({ visibility: 'hidden', display: 'none' })
  $('.view-main').css({ opacity: '1' })
  $('.header-main').css({ opacity: '1' })
}

export const EventListItem = (props) => {
  const { urlEncode } = UrlEncodeHook()

  const dispatch = useDispatch()
  const history = useHistory()
  let event = props.event

  const [inputs, setInputs] = useState({})
  const [additionalInfo, setadditionalInfo] = useState('')
  const [showOpen, setShowOpen] = useState([])
  const [manualEntryToggle, setManualEntryToggle] = useState(false)
  const [easyEntryToggle, setEasyEntryToggle] = useState(false)
  const [additionalToggle, setAdditionalToggle] = useState(false)

  const emailRegex = new RegExp('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$')

  const [showYouth, setShowYouth] = useState([])
  let [showBtn, setShowBtn] = useState(true)
  let [showSuccessPopup, setShowSuccessPopup] = useState(false)
  let [showPopup, setShowPopup] = useState(false)
  const [showAlert, setShowAlert] = useState('')
  const [msg, setMsg] = useState('')
  const [discounts, setDiscounts] = useState([])
  const [costInfo, setCostInfo] = useState([])
  const [deadlines, setDeadlines] = useState([])
  const [downloadCatalogEvent, setDownloadCatalogEvent] = useState(null)
  const [runTotal, setRunTotal] = useState(0)
  const [modal_center, setmodal_center] = useState(false)

  //
  const [showModalPremier, setShowModalPremier] = useState(false)

  function removeBodyCss() {
    document.body.classList.add('no_padding')
  }

  const userData = useSelector((state) => state.Login)

  const premier = useMemo(() => {
    let isPremierUser = false
    if (_.size(userData.planListObj)) {
      const p = _.first(userData.planListObj['premier'])
      const combined = _.first(userData.planListObj['combined'])
      if (combined?.plan_expired === 0) {
        isPremierUser = true
      } else if (p?.plan_expired === 0) {
        isPremierUser = true
      }
    }
    return isPremierUser
  }, [userData.planListObj])

  let count = 0
  function handelEasyEntryClick(ev) {
    setEasyEntryToggle(true)
    if (localStorage.getItem('accessToken')) {
      if (!premier) {
        setShowModalPremier(true)
        // dispatch(setShowAlertp(true))
        // dispatch(
        //   setMsgp('You must be a Premier User to participate in an event')
        // )
        // dispatch(setShowBtnp(true))
        // dispatch(setBtnTextp('Continue'))
        // toast.error('You must be a Premier User to participate in an event')
        setEasyEntryToggle(false)
        return
      }
      localStorage.setItem('events', JSON.stringify(ev))
      get(
        `/api/v1/participation/validate-event-data-exist?event_id=${event.event_id}`
      )
        .then((res) => {
          setEasyEntryToggle(false)
          dispatch(
            setHandleClickp(() => {
              if (localStorage.getItem('accessToken')) {
                history.push({
                  pathname: `/participate_easy_entry`,
                  search: `?event_id=${urlEncode(ev.event_id)}&has_products=${
                    ev.has_products
                  }`,
                  state: {
                    event: event,
                    additionalInfo: additionalInfo,
                    costInfo: costInfo,
                    showOpen: showOpen,
                    showYouth: showYouth,
                    runTotal: runTotal,
                    hasAdditionalProduct: ev.has_products,
                  },
                })
              } else {
                history.push('/login')
              }
            })
          )
          dispatch(setShowAlertp(true))
          dispatch(
            setMsgp(
              'You must update your Easy Entry, before you participate in an event. Only limited changes can be made in the Participation process '
            )
          )
          dispatch(setShowBtnp(true))
          dispatch(setBtnTextp('Continue'))
        })
        .catch((err) => {
          if (err.response.status === 409) {
            toast.error(
              'Something is not right! either you have a pending shopping cart to process or the event you wish to participate may be restricted by the event secretary. If you have any questions please get in touch with the event secretary.'
            )
            setTimeout(() => {
              history.push('/shopping_cart')
              setEasyEntryToggle(false)
            }, 1500)
          }
        })
    } else {
      history.push('/login')
    }
  }
  function contact_center_modal() {
    setmodal_center(!modal_center)
    removeBodyCss()
  }
  const getRunningTotal = () => {
    //alert(event.event_id);
    get(`/api/v1/participation/running-total/${event.event_id}`)
      .then((res) => {
        setRunTotal(res.data ? res.data.total_entries : 0)
      })
      .catch((error) => {
        console.log(error)
      })
  }
  const MINUTE_MS = 50000
  useEffect(() => {
    getRunningTotal()
    const interval = setInterval(() => {
      getRunningTotal()
    }, MINUTE_MS)
    return () => clearInterval(interval)
  }, [event])

  useEffect(() => {
    get(`api/v1/events/${event.event_id}/additionalInfo`)
      .then((responce) => {
        setadditionalInfo(responce.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [event])

  useEffect(() => {
    get(
      `api/v1/shows/all?show_type=open&event_id=${event.event_id}&breed_list=1`
    )
      .then((responce) => {
        //console.log('responce.data=', responce.data);
        setShowOpen(responce.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [event])

  useEffect(() => {
    get(
      `api/v1/shows/all?show_type=youth&event_id=${event.event_id}&breed_list=1`
    )
      .then((responce) => {
        setShowYouth(responce.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [event])

  useEffect(() => {
    get(`api/v1/events/${event.event_id}/discounts`)
      .then((responce) => {
        //console.log(responce.data);
        setDiscounts(responce.data)
      })
      .catch((error) => {
        console.log(error)
      })

    get(`api/v1/events/${event.event_id}/cost`)
      .then((responce) => {
        //console.log('responce.data', responce.data);
        setCostInfo(responce.data)
      })
      .catch((error) => {
        console.log(error)
      })

    get(`api/v1/events/${event.event_id}/deadline`)
      .then((responce) => {
        //console.log('responce.data', responce.data);
        setDeadlines(responce.data)
      })
      .catch((error) => {
        console.log(error)
      })

    $('#display_popup').on('click', function () {
      showpopup()
    })
    $('#cancel_button').on('click', function () {
      hidepopup()
    })
  }, [event])

  // events

  const onSaveAdditionalProduct = (event) => {
    let eventData = {
      event_id: event.event_id,
      address_1: event.address_1,
      address_2: event.address_2,
      city: event.city,
      country_name: event.country_name,
      end_date_time: event.end_date_time,
      event_name: event.event_name,
      dealines: event.deadlines,
      state_name: event.state_name,
      deadline_status: event.deadline_status,
    }
    localStorage.setItem('events', JSON.stringify(eventData))
  }

  //
  var deadlineArray = event.deadlines

  let displayEnd = true
  if (getDate(event?.start_date_time) === getDate(event?.end_date_time)) {
    displayEnd = false
  }

  return (
    <div className='col-md-12 mb-9'>
      <div className='card shadow-only-hover'>
        <div className='card-body event-card-body'>
          <div className='col-lg-12 col-xl-12 event-box'>
            <div className='row'>
              <div className='col-lg-4 col-xl-3 box-head'>
                <div className='row'>
                  <div className='col-lg-5 col-xl-5 mb-4 mt-3 res-txt-center'>
                    Event Date :
                  </div>
                  <div className='col-lg-7 col-xl-7 mb-4 pl-0'>
                    <div className='highlight'>
                      {getDate(event?.start_date_time)}
                      <br />

                      {displayEnd && 'To'}
                      {displayEnd && <br />}
                      {displayEnd && getDate(event?.end_date_time)}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className='col-lg-5 col-xl-3 box-head mt-3'
                style={{ textAlign: 'center' }}
              >
                {event.event_name}
              </div>
              <div class='col-lg-3 col-xl-3 box-head mt-3 res-txt-center res-mb-2'>
                {_.join(_.compact([event.city, event.state_name]), ', ')}
              </div>
              <div className='col-lg-3 col-xl-3 box-head res-mb-2'>
                <div className='row'>
                  <div className='col-lg-4 col-xl-4 mb-4 mt-2 p-0 text-center'>
                    Deadline :
                  </div>

                  {deadlineArray?.map((item, index) => {
                    if (item.deadline_status === 'upcoming' && count === 0) {
                      count++
                      return (
                        <div className='col-lg-8 col-xl-8 view-dline mb-4'>
                          <span className='deadline'>
                            <div>
                              {getDate(item.date_time)}
                              <br />
                              {`${getTime(item.date_time)} CST`}
                            </div>
                          </span>
                          <div className='input-group-append'>
                            <span className='input-group-text'>
                              {ordinal_suffix_of(index + 1)}
                            </span>
                          </div>
                        </div>
                      )
                    }
                  })}
                </div>
              </div>

              <div className='col-lg-9 col-xl-9 box-head mb-2 res-txt-center res-mb-2'>
                <span className='running'>{runTotal}</span>{' '}
                <u
                  id={`click-here-${event.event_id}`}
                  onClick={() => {
                    runTotal !== 0 && handlRunningTotalColapse(event.event_id)
                  }}
                  className='pe-auto'
                >
                  Click here for Running Total{' '}
                </u>
              </div>
              <div className='col-lg-6 col-xl-3 box-head wm-100'>
                <div className='row'>
                  <div className='col-lg-4 col-xl-4 mb-2 text-center mt-2 p-0'>
                    Payment :{' '}
                  </div>
                  <div className='col-lg-8 col-xl-8 mb-2 pl-0'>
                    <p className='payment'>
                      {event.is_online_payment === 1 ? 'Online' : 'Offline'}
                    </p>
                  </div>
                </div>
              </div>
              <RunningTotal
                event={event}
                handlRunningTotalColapse={handlRunningTotalColapse}
              />
              <div className='col-lg-12 col-xl-12 box-btn text-center mt-2'>
                <div
                  className='col-lg-12 col-xl-12 mb-3 mt-6 toggle-example collapse'
                  id={`toggle-example-${event.event_id}`}
                >
                  <div className='row justify-content-center'>
                    <div className='col-lg-3 text-center res-mb-2'>
                      <button
                        type='button'
                        className='btn btn-primary'
                        onClick={() =>
                          setDownloadCatalogEvent(
                            event.event_id
                            // !downloadCatalogEvent ? event.event_id : null,
                          )
                        }
                      >
                        DOWNLOAD CATALOG
                      </button>
                    </div>
                    {additionalInfo &&
                      additionalInfo?.secretary?.product_permission === 1 &&
                      event &&
                      event.has_products === 1 && (
                        <div className='col-lg-3 text-center res-mb-2'>
                          <button
                            type='button'
                            className='btn btn-primary'
                            disabled={additionalToggle}
                            onClick={(e) => {
                              if (localStorage.getItem('accessToken')) {
                                setAdditionalToggle(true)
                                get(
                                  `/api/v1/participation/validate-event-data-exist?event_id=${event.event_id}`
                                )
                                  .then((res) => {
                                    setAdditionalToggle(false)
                                    localStorage.setItem(
                                      'events',
                                      JSON.stringify(event)
                                    )
                                    history.push({
                                      pathname: '/additional_products',
                                      state: {
                                        event: event,
                                        additionalInfo: additionalInfo,
                                        costInfo: costInfo,
                                        showOpen: showOpen,
                                        showYouth: showYouth,
                                        hasShoppingCart: false,
                                      },
                                    })
                                  })
                                  .catch((err) => {
                                    toast.error(
                                      'Something is not right! either you have a pending shopping cart to process or the event you wish to participate may be restricted by the event secretary. If you have any questions please get in touch with the event secretary.'
                                    )
                                    setTimeout(() => {
                                      history.push('/shopping_cart')
                                      setAdditionalToggle(false)
                                    }, 1500)
                                  })
                              } else {
                                history.push('/login')
                              }
                            }}
                          >
                            ADDITIONAL PRODUCT
                          </button>
                        </div>
                      )}
                    {/* <div className="col-lg-3 text-center">
                      <button type="button" className="btn btn-primary">
                        Sponsor Sanction
                      </button>
                    </div> */}
                    {/* <div className="col-lg-3 text-center">
                      <button type="button" className="btn btn-primary">
                        Sponsor Awards
                      </button>
                    </div> */}
                  </div>
                  <br />
                  <hr /> <br />
                  {downloadCatalogEvent ? (
                    <DownloadCatalog
                      closeBTN={() =>
                        setDownloadCatalogEvent(
                          null
                          // !downloadCatalogEvent ? event.event_id : null,
                        )
                      }
                      eventId={downloadCatalogEvent}
                    />
                  ) : (
                    <Fragment>
                      <AdditionalInformation
                        additionalInfo={additionalInfo}
                        contact_center_modal={contact_center_modal}
                      />
                      <br />
                      {deadlines?.length > 0 && (
                        <EventMultipleDeadline deadlines={deadlines} />
                      )}
                      {costInfo?.length > 0 && (
                        <CostInformation
                          costInfo={costInfo}
                          showOpen={showOpen}
                          showYouth={showYouth}
                        />
                      )}
                      {discounts?.length > 0 && (
                        <DiscountInformation discounts={discounts} />
                      )}
                      {deadlineArray.length &&
                      deadlineArray[0].deadline_status === 'upcoming' ? (
                        <React.Fragment>
                          <hr /> <br />
                          <div className='row justify-content-center'>
                            <div className='col-lg-12 col-xl-12 mb-4 box-head text-center'>
                              Participate :
                              <button
                                type='button'
                                className='btn btn-primary btn-easy res-w-100 res-mb-2'
                                disabled={easyEntryToggle}
                                onClick={() => {
                                  setShowPopup(true)
                                  handelEasyEntryClick(event)
                                }}
                              >
                                EASY ENTRY
                              </button>
                              <button
                                type='button'
                                className='btn btn-primary btn-manual res-w-100 res-mb-2'
                                disabled={manualEntryToggle}
                                onClick={() => {
                                  if (localStorage.getItem('accessToken')) {
                                    setManualEntryToggle(true)

                                    get(
                                      `/api/v1/participation/validate-event-data-exist?event_id=${event.event_id}`
                                    )
                                      .then((res) => {
                                        localStorage.setItem(
                                          'events',
                                          JSON.stringify(event)
                                        )
                                        setManualEntryToggle(false)
                                        history.push({
                                          pathname: `/participate_manual_entry`,
                                          search: `?event_id=${urlEncode(
                                            event.event_id
                                          )}&has_products=${
                                            event.has_products
                                          }`,
                                          state: {
                                            event: event,
                                            additionalInfo: additionalInfo,
                                            costInfo: costInfo,
                                            showOpen: showOpen,
                                            showYouth: showYouth,
                                            hasAdditionalProduct:
                                              event.has_products,
                                            runTotal: runTotal,
                                          },
                                        })
                                      })
                                      .catch((err) => {
                                        if (err.response.status === 409) {
                                          toast.error(
                                            'Something is not right! either you have a pending shopping cart to process or the event you wish to participate may be restricted by the event secretary. If you have any questions please get in touch with the event secretary.'
                                          )
                                          setTimeout(() => {
                                            history.push('/shopping_cart')
                                            setManualEntryToggle(false)
                                          }, 1500)
                                        }
                                      })
                                  } else {
                                    history.push('/login')
                                  }
                                }}
                              >
                                MANUAL ENTRY
                              </button>
                            </div>
                          </div>
                        </React.Fragment>
                      ) : null}
                    </Fragment>
                  )}
                </div>
                <a
                  onClick={() => {
                    handleClick(event.event_id)
                  }}
                >
                  <i
                    className='fa fa-chevron-circle-down down-icon'
                    id={`down-icon-${event.event_id}`}
                    onClick={() => {
                      circle(event.event_id)
                    }}
                  ></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <GetInTouchModal
        modal_center={modal_center}
        setmodal_center={setmodal_center}
        setMsg={setMsg}
        setShowAlert={setShowAlert}
        setShowBtn={setShowBtn}
        setShowSuccessPopup={setShowSuccessPopup}
        event_id={event.event_id}
      />

      {showSuccessPopup && (
        <SuccessPopup
          setShowAlert={setShowAlert}
          msg={msg}
          showAlert={showAlert}
          setMsg={setMsg}
          setShowBtn={setShowBtn}
          showBtn={showBtn}
        />
      )}
      {showPopup && <Popup />}
      <Modal
        isOpen={showModalPremier}
        // toggle={() => {
        //   contact_center_modal()
        // }}
        centered={true}
      >
        <div className='modal-header' style={{ background: '#fff' }}>
          <div className='modal-title mt-0' />
          <button
            type='button'
            onClick={() => {
              setShowModalPremier(false)
            }}
            className='close close-btn'
            data-dismiss='modal'
            aria-label='Close'
          >
            <span aria-hidden='true'>&times;</span>
          </button>
        </div>
        <div className='modal-body' style={{ background: '#fff' }}>
          <Row className='mb-2'>
            <Col lg={12}>
              <p className='fs-5 text-dark fw-bold'>
                The Easy Entry feature is exclusively available for Premier
                Members. Become a Premier Member today to unlock this feature
                and enjoy premium benefits!
              </p>
            </Col>
            <div class='col-md-12 pt-3 text-center'>
              <Link to='/premier-membership'>
                <button
                  class='btn btn-primary btn-small'
                  type='submit'
                  id='btn-get-in-touch'
                  name='send'
                >
                  Purchased Now
                </button>
              </Link>
            </div>
          </Row>
        </div>
      </Modal>
    </div>
  )
}
