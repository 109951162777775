import React from 'react'
import { Col, InputGroup } from 'reactstrap'
import Flatpickr from 'react-flatpickr'
import monthSelect from 'flatpickr/dist/plugins/monthSelect'

const DateFilterByMonth = ({ onChange, inputs }) => {
  return (
    <React.Fragment>
      <Col lg={2}>
        <label className='form-check-label mt-3 ' htmlFor='check1'>
          Date:
        </label>
      </Col>
      <Col lg={8}>
        <div className=''>
          <InputGroup>
            <Flatpickr
              id='StartFlatpickr'
              className='form-control d-block glyphicon glyphicon-calendar'
              placeholder={`Select Month`}
              name='startDate'
              options={{
                minDate: '2018-01-01',
                plugins: [
                  monthSelect({
                    shorthand: true, // Display months in shorthand format (e.g., Jan, Feb)
                    dateFormat: 'Y-m', // Display format
                    altFormat: 'F Y', // Alternate display format
                  }),
                ],
              }}
              value={inputs.startDate ? inputs.startDate : ''}
              style={{
                width: '100%',
              }}
              onChange={onChange}
            />
            <i className='fa fa-calendar cal-icon'></i>
          </InputGroup>
        </div>
      </Col>
    </React.Fragment>
  )
}

export default DateFilterByMonth
